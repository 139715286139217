<template>
  <div class="person-detail">
    <ori-header />

    <div class="container">
      <div class="w1200 bread-wrap">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/ori-person' }"
            >人才库</el-breadcrumb-item
          >
          <el-breadcrumb-item>人才详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>

    <div class="container">
      <div class="w1200 detail-content">
        <div class="panel">
          <div class="img">
            <img src="http://dummyimage.com/120x120/6F69AC" alt="" srcset="" />
          </div>
          <div class="info">
            <div class="name">
              <span>何艳</span>
              <span>Ronald Gonzalez</span>
            </div>
            <div class="bottom-list">
              <div class="left">
                <div class="list-item">
                  <span>出生年月：</span>
                  <span>1990-01-16</span>
                </div>
                <div class="list-item">
                  <span>年&nbsp;&nbsp;龄：</span>
                  <span>30岁</span>
                </div>
                <div class="list-item">
                  <span>专业领域：</span>
                  <span>工学-城乡规划学-城乡规划管理</span>
                </div>
              </div>
              <div class="right">
                <div class="list-item">
                  <span>邮&nbsp;&nbsp;箱：</span>
                  <span>******************</span>
                </div>
                <div class="list-item">
                  <span>联系电话：</span>
                  <span>***********</span>
                </div>
                <div class="list-item">
                  <span>联系地址：</span>
                  <span>**********</span>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-wrap">
            <el-button size="medium" type="primary">查看名片</el-button>
          </div>
        </div>

        <div class="detail-wrap">
          <div class="left">
            <div class="nav-list">
              <span class="active">基本信息</span>
              <span>专长特点</span>
              <span>教育经历</span>
              <span>工作经历</span>
              <span>就业意向</span>
            </div>
            <div class="info">
              <div class="info-item">
                <div class="title">基本信息</div>
                <el-descriptions
                  size="small"
                  labelClassName="info-label"
                  contentClassName="info-value"
                  :colon="false"
                >
                  <el-descriptions-item label="性别">男</el-descriptions-item>

                  <el-descriptions-item label="毕 业 业 院 校"
                    >*****</el-descriptions-item
                  >

                  <el-descriptions-item label="工作总年限">
                    5-10年
                  </el-descriptions-item>
                  <el-descriptions-item label="国籍">中国</el-descriptions-item>
                  <el-descriptions-item label="毕业院校(英文)"
                    >***************************</el-descriptions-item
                  >
                  <el-descriptions-item label="前工作单位"
                    >利物浦大学</el-descriptions-item
                  >
                  <el-descriptions-item label="出生地"
                    >江苏</el-descriptions-item
                  >
                  <el-descriptions-item label="学位">博士</el-descriptions-item>
                  <el-descriptions-item label="职务"
                    >Tutor</el-descriptions-item
                  >
                  <el-descriptions-item label="微信"
                    >************</el-descriptions-item
                  >
                  <el-descriptions-item label="现有职称"
                    >技术员级</el-descriptions-item
                  >
                  <el-descriptions-item label="现任单位"
                    >The University of Liverpool</el-descriptions-item
                  >
                  <el-descriptions-item label="邮编">中国</el-descriptions-item>
                  <el-descriptions-item label="专业方向"
                    >不同国家间城乡规划比较</el-descriptions-item
                  >
                </el-descriptions>
              </div>

              <div class="info-item">
                <div class="title">专长特点</div>
                <div class="info-desc">
                  <div class="sub-title">个人专长：</div>
                  <div class="desc">
                    本人主要从事中国台湾，英国及欧盟各国都市，乡村与社区空间及土地规划（含海洋规划）的相关资源，政策，经济社会与政府能力相关研究。同时涉及区域与地方战略性规划研究及规划过程的评估。擅长对土地资源，政府职能，文化环境和规划文化对比性分析。目前工作为英文授课本，硕生以及对群众的教学及助教任务。研究论文包括
                    《关于提升中国环境评估过程中公众参与效率的研究》；《英格兰西北部社区开展创建社区规划主动性的研究》，《少数名族部落和乡村规划的研究和实践（进行中）》，《中国台湾城市规划过程中对众教育的探析（进行中）》，《英国，台湾和大陆社区规划实践与研究进展比较探析（进行中）》。Xinxin
                    is an Associate Fellow at the University of Liverpool since
                    2020. A cross-national research background with teaching
                    portfolio and commission to the development of spatial and
                    territorial planning. By applying both qualitative and
                    quantitative research methods in recent studies, I’ve draw
                    attending on the theory and practices during both tactical
                    and strategic planning process.
                  </div>
                </div>
                <div class="info-desc">
                  <div class="sub-title">以往从事工作业绩：</div>
                  <div class="desc">
                    2020—至今 讲师 继续教育学院，利物浦大学，英国 
                    独立承担教学内容设计及课件准备等。推广课程和参与课程管理。
                    2017—至今 助教 课程：与环境变化共存，利物浦大学环境科学学院
                    
                    承担教学环境科学学院本科任务，课程以英文演讲，研讨小组及田野调查为主。培养学生好奇心和挑战及分析思维，培养学生独立思考和辨证能力
                    2018—至今 私教 优越留学，伦敦，英国 
                    答疑解惑，针对性辅导及写作成果分析与提高。帮助本科和研究生学生申请理
                    2017 进程监督 英格兰规划援助发展计划 
                    检阅全年社区规划发展进程及完成度分析并反馈给皇家规划院。主动积极开展调研和数据核实，帮助团队跟新数据和及时对上级反馈。报告结果对今年英国社区住房部门制定未来社区规划起到支撑作用。
                    2016 辅导 Birkenhead and Tramere 社区规划咨询 
                    帮助普通群众策划及开展社区规划计划的开展，并收集信息和意见反馈
                    2016 咨询员 沃灵顿市规划‘首选发展方案’ 民众参与 
                    帮助市政府召开规划议程及辅助市规划单位向群众解释规划内容及变更区规划情况，并收集群众反馈
                    2016 辅导员 Morecambe 社区发展计划咨询会 2015-2019
                    规划师志愿者 英格兰规划援助（隶属皇家城市规划协会） 
                    学习目前规划法律法规变更，及时了解规划评估及报告审查的重点
                    2016 报告检查员 英国环境部环境评估 
                    检阅全年环评报告，按照环评报告质量评估框架
                  </div>
                </div>
              </div>

              <div class="info-item">
                <div class="title">教育经历</div>
                <el-descriptions
                  :column="2"
                  size="small"
                  labelClassName="info-label"
                  contentClassName="info-value"
                  :colon="false"
                >
                  <el-descriptions-item label="国家">英国</el-descriptions-item>

                  <el-descriptions-item label="国家">英国</el-descriptions-item>

                  <el-descriptions-item label="院校"
                    >利物浦大学</el-descriptions-item
                  >
                  <el-descriptions-item label="院校"
                    >利物浦大学</el-descriptions-item
                  >

                  <el-descriptions-item label="学位">学士</el-descriptions-item>
                  <el-descriptions-item label="学位">硕士</el-descriptions-item>

                  <el-descriptions-item label="专业领域"
                    >工学-城乡规划学-城乡规划与设计</el-descriptions-item
                  >
                  <el-descriptions-item label="专业领域"
                    >工学-环境科学与工程（可授工学、理学、农学学位）-环境科学</el-descriptions-item
                  >

                  <el-descriptions-item label="专业方向"
                    >环境与规划</el-descriptions-item
                  >
                  <el-descriptions-item label="专业方向"
                    >环境评估与管理</el-descriptions-item
                  >

                  <el-descriptions-item label="导师"></el-descriptions-item>
                  <el-descriptions-item label="导师"></el-descriptions-item>

                  <el-descriptions-item label="经历时间"
                    >2012-09-08 - 2015-06-17</el-descriptions-item
                  >
                  <el-descriptions-item label="经历时间"
                    >2015-09-09 - 2016-07-27</el-descriptions-item
                  >
                </el-descriptions>
              </div>

              <div class="info-item">
                <div class="title">工作经历</div>
                <div class="item-row">
                  <el-row>
                    <el-col :span="12">
                      <el-descriptions
                        :column="1"
                        size="small"
                        labelClassName="info-label"
                        contentClassName="info-value"
                        :colon="false"
                      >
                        <el-descriptions-item label="国家"
                          >英国</el-descriptions-item
                        >

                        <el-descriptions-item label="单位"
                          >利物浦大学</el-descriptions-item
                        >
                        <el-descriptions-item label="职务"
                          >讲师</el-descriptions-item
                        >

                        <el-descriptions-item label="职称"
                          >无</el-descriptions-item
                        >

                        <el-descriptions-item label="经历时间"
                          >2020-02-18 - 2021-02-01</el-descriptions-item
                        >
                      </el-descriptions>
                    </el-col>
                    <el-col :span="12">
                      <el-descriptions
                        :column="1"
                        size="small"
                        labelClassName="info-label"
                        contentClassName="info-value"
                        :colon="false"
                      >
                        <el-descriptions-item label="国家"
                          >英国</el-descriptions-item
                        >

                        <el-descriptions-item label="单位"
                          >利物浦大学</el-descriptions-item
                        >
                        <el-descriptions-item label="职务"
                          >助教</el-descriptions-item
                        >

                        <el-descriptions-item label="职称"
                          >无</el-descriptions-item
                        >

                        <el-descriptions-item label="经历时间"
                          >2016-02-09 - 2021-02-01</el-descriptions-item
                        >
                      </el-descriptions>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="info-item">
                <div class="title">就业意向</div>
                <el-descriptions
                  :column="1"
                  size="small"
                  labelClassName="info-label-right"
                  contentClassName="info-value"
                  :colon="false"
                >
                  <el-descriptions-item label="意向就业地区">
                    <span class="btn">江苏省-南京市</span>
                  </el-descriptions-item>
                  <el-descriptions-item label="意向企业类型">
                    <span class="btn">科研组织</span>
                    <span class="btn">事业单位</span>

                    <span class="btn">政府机关</span>
                  </el-descriptions-item>

                  <el-descriptions-item label="期望担任岗位">
                    <span class="btn">科研</span>
                    <span class="btn">科研人员</span>
                  </el-descriptions-item>
                  <el-descriptions-item label="任职模式">
                    <span class="btn">全职</span>
                  </el-descriptions-item>

                  <el-descriptions-item label="期望薪酬模式">
                    <span class="btn">提成工资制</span>
                    <span class="btn">岗位工资制</span>
                    <span class="btn">年薪工资制 </span>
                  </el-descriptions-item>

                  <el-descriptions-item label="期望薪酬">
                    <span>暂无</span>
                  </el-descriptions-item>
                </el-descriptions>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="head">
              <span>推荐人才</span>
              <span>更多></span>
            </div>
            <div class="reco-list">
              <div class="box" v-for="i in 3" :key="i">
                <div class="avatar">
                  <img
                    src="http://dummyimage.com/80x80/3DB2FF"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="info">
                  <div class="name">
                    <span> 蒋秀兰 </span>
                    <span>Elizabeth Thompson</span>
                  </div>
                  <div class="info-row">
                    <span>学位：</span>
                    <span>硕士</span>
                  </div>
                  <div class="info-row">
                    <span>任职模式：</span>
                    <span>全职</span>
                  </div>
                  <div class="info-row">
                    <span>专业领域：</span>
                    <span>理学-生物学-遗传学</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <page-foot />
  </div>
</template>

<script>
import PageFoot from "../../../components/ori-com/PageFoot.vue";
export default {
  components: { PageFoot },
};
</script>

<style lang="scss" scoped>
.person-detail {
  background-color: #f9f9f9;

  .container {
    width: 100%;
    overflow: hidden;
  }

  .bread-wrap {
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    background-color: #fff;
  }

  .detail-content {
    .panel {
      position: relative;
      margin: 10px 0 20px 0;
      background: #fff;
      padding: 28px;
      padding-right: 40px;

      display: flex;
      align-items: flex-start;

      .img {
        width: 120px;
        height: 120px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 26px;
      }

      .btn-wrap {
        position: absolute;
        top: 80px;
        right: 40px;
      }

      .info {
        flex: 1 1 auto;
        padding-top: 10px;
        .name {
          margin-bottom: 5px;
          span:nth-child(1) {
            font-size: 16px;
            font-weight: 700;
            color: #333333;
          }
          span:nth-child(2) {
            margin-left: 12px;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
          }
        }

        .bottom-list {
          display: flex;
          align-items: center;
          .left {
            width: 300px;
          }
          .right {
            width: 250px;
          }

          .list-item {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 8px;
            font-size: 12px;
            font-weight: 400;
            color: #999;
            line-height: 16px;
            span:nth-child(1) {
              display: inline-block;
              width: 65px;
              text-align: justify;
              text-align-last: justify;
            }
            span:nth-child(2) {
              color: #666;
            }
          }
        }
      }
    }

    .detail-wrap {
      margin-bottom: 30px;
      display: flex;
      align-items: flex-start;

      .left {
        flex: 1 1 auto;

        .nav-list {
          height: 50px;
          line-height: 50px;
          background-color: #fff;

          span {
            cursor: pointer;
            display: inline-block;
            padding: 0 50px;
            font-size: 14px;
            font-weight: 400;
            color: #666666;

            &:hover,
            &:active,
            &.active {
              background: #e62d31;
              color: #fff;
            }
          }
        }

        .info {
          margin-top: 15px;
          background: #fff;
          padding: 20px 30px;

          .info-item {
            margin-bottom: 30px;

            .title {
              border-left: 2px solid #e62d31;
              font-size: 14px;
              padding-left: 8px;
              font-weight: 700;
              color: #333333;
              line-height: 14px;
              position: relative;
              left: -10px;
              margin-bottom: 20px;
            }
            .btn {
              // display: inline-block;
              margin-right: 4px;
              padding: 2px 10px;
              height: 24px;
              background: #f4f5f9;
              border-radius: 2px;
            }

            .item-row {
              margin-bottom: 20px;
            }

            ::v-deep .el-descriptions-item {
              vertical-align: top;
            }

            ::v-deep .info-label {
              width: 80px;
              color: #999999;
              text-align: justify;
              text-align-last: justify;
            }

            ::v-deep .info-label-right {
              width: 90px;
              color: #999999;
              text-align: right;
            }

            ::v-deep .info-value {
              color: #666;
            }

            .info-desc {
              color: #666;
              line-height: 24px;
              font-size: 12px;
              margin-bottom: 10px;
              .sub-title {
                font-weight: 400;
                color: #999;
              }
            }
          }
        }
      }
      .right {
        margin-left: 15px;
        width: 330px;
        flex: 0 0 auto;

        .head {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 17px 0 13px;
          background: #fff;
          height: 50px;
          line-height: 50px;
          span:nth-child(1) {
            font-size: 14px;
            font-weight: 700;
            color: #333333;
          }
          span:nth-child(2) {
            font-size: 12px;
            cursor: pointer;
            font-weight: 400;
            color: #999;
          }
        }

        .reco-list {
          margin-top: 15px;
          padding: 0 15px;
          background: #fff;

          .box {
            display: flex;
            align-items: flex-start;
            padding: 20px 0;
            & + .box {
              border-top: 1px solid #f4f4f4;
            }

            .avatar {
              flex: 0 0 auto;
              width: 80px;
              height: 80px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 20px;
              img {
                width: 80px;
                height: 80px;
                transition: all 0.8s ease-in;
              }
            }

            .info {
              flex: 1 1 auto;

              .name {
                margin-bottom: 4px;
                font-size: 14px;
                font-weight: 700;
                color: #333333;
                max-width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                span:nth-child(2) {
                  font-size: 12px;
                  font-weight: 400;
                  color: #666666;
                  margin-left: 10px;
                }
              }

              .info-row {
                font-size: 12px;
                font-weight: 400;
                color: #666666;
                margin-bottom: 4px;
                span:nth-child(1) {
                  color: #999;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>